<template>
  <v-overlay
    color="rgba(0, 0, 0, 0.6)"
    opacity="1"
    v-model="model"
  >
    <v-card
      flat
      color="transparent"
    >
      <v-card-text
        class="white--text"
      >
        <h2>Drop file here</h2>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  name: 'DropHereOverlay',
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>